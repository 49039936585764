<template>
  <div>
    <ValidationProvider
      :rules="localRules"
      name="الصورة"
      ref="imageValidation"
      v-slot="{errors}"
    >
      <div :class="errors[0] ? imageRequiredClass : ''">
        <file-pond
          :allow-image-preview="false"
          :allow-multiple="multiple"
          :chunk-uploads="true"
          :files="[]"
          :image-resize-target-height="480"
          :image-resize-target-width="820"
          :image-resize-upscale="false"
          :image-transform-output-quality="70"
          :max-files="5"
          :onaddfilestart="addfilestart"
          :onprocessfile="successUpload"
          :onprocessfiles="filesUploaded"
          :server="server"
          accepted-file-types="image/jpeg, image/png, image/jpeg"
          label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
          name="file"
          ref="pond"
        />
      </div>
      <p
        class="text-danger"
        style="text-align: center"
      >
        {{ errors[0] }}
      </p>
    </ValidationProvider>
    <vs-row>
      <template v-if="multiple">
        <vs-col
          :key="index"
          class="p-2"
          v-for="(file, index) in files"
          vs-align="center"
          vs-justify="end"
          vs-lg="3"
          vs-sm="6"
          vs-type="flex"
          vs-xs="12"
        >
          <vx-card class="overlay-card overflow-hidden">
            <template slot="no-body">
              <img
                :src="file"
                alt="user-profile-cover"
                class="responsive"
                style="height: 300px; object-fit: cover"
              >
              <div class="card-overlay text-white flex flex-col">
                <div class="flex flex-row-reverse">
                  <vs-button
                    @click="clearFile(index)"
                    color="rgb(255, 255, 255)"
                    icon="icon-trash"
                    icon-pack="feather"
                    size="small"
                    type="border"
                    v-show="file"
                  />
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </template>
      <template v-if="file !== null && file !== undefined && file !== ''">
        <vs-col
          vs-align="left"
          vs-justify="end"
          vs-type="flex"
          vs-xs="12"
        >
          <vx-card class="overlay-card overflow-hidden">
            <template slot="no-body">
              <img
                :src="file"
                alt="user-profile-cover"
                class="responsive"
                style="height: 300px; object-fit: cover"
              >
              <div class="card-overlay text-white flex flex-col">
                <div class="flex flex-row-reverse">
                  <vs-button
                    @click="clearFile()"
                    color="rgb(255, 255, 255)"
                    icon="icon-trash"
                    icon-pack="feather"
                    size="small"
                    type="border"
                    v-show="file"
                  />
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </template>
    </vs-row>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

import {environment} from "@/environment";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);
export default {
  name: "FilePondComponent",
  props: {
    files: {
      type: Array,
      default: undefined
    },
    file: {
      type: String,
      default: null
    },
    project: {
      type: String,
      required: true
    },
    rules: {
      type: Object,
      default: () => ({required: true})
    },
    env: {
      type: String,
      default: 'local'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localRules: this.rules,
      imageRequiredClass: 'imageRequiredClass',
      server: {
        process: `${environment.apiBaseURL}files/projects/${this.project}`,
        fetch: null
      },
      uploadedFiles: [],
      tempRules: ''
    };
  },
  methods: {
    successUpload(error, file) {
      this.uploadedFiles.push(JSON.parse(file.serverId).data.file);
    },
    filesUploaded() {
      const files = this.multiple ? this.uploadedFiles : this.uploadedFiles[0];
      this.$emit('fileUploaded', files);
      this.$refs.imageValidation.reset();
      this.uploadedFiles = [];
      this.tempRules = this.localRules;
      this.makeRequiredFalse();
    },
    addfilestart() {
      this.$emit('uploading');
    },
    clearFile(index = undefined) {
      // if (this.uploadedFiles.length === 0) this.makeRequiredFalse();
      if (this.uploadedFiles.length === 0) this.localRules = {...this.rules};
      this.$emit('fileDeleted', index);
    },
    validateProps() {
      if (this.multiple && !this.files)
        console.error('files prop in FilePondComponent required');
      else if (!this.multiple && this.file === null || this.file === undefined) {
        console.error('file prop in FilePondComponent required');
      }
    },
    initValidation() {
      this.localRules = this.tempRules;
    },
    makeRequiredFalse() {
      this.localRules = {...this.localRules, required: false};
    }
  },
  components: {
    FilePond
  },
  created() {
    if ((this.files && this.files.length > 0) || this.file)
      this.localRules = {...this.rules};
      // this.makeRequiredFalse();
    this.validateProps();
  },
  watch: {
    file: function (newVal) {
      if (newVal !== '' && newVal !== null && newVal !== undefined)
        this.makeRequiredFalse();
    },
    files: function (newVal) {
      if (newVal && newVal > 0)
        this.makeRequiredFalse();
    }
  }
};
</script>
<style>
.filepond--root {
  margin-bottom: 1px;
}

.imageRequiredClass {
  border: 2px solid red;
  border-radius: 10px
}

.vx-card .vx-card__collapsible-content .card-overlay::after {
  margin: auto;
  background: rgba(0, 0, 0, 0.54);
}
</style>
